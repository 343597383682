export default [
  {
    text: '首页',
    id: '0'
  },
  {
    text: '动画',
    id: '1'
  },
  {
    text: '番剧',
    id: '2'
  },
  {
    text: '国创',
    id: '3'
  },
  {
    text: '音乐',
    id: '4'
  },
  {
    text: '舞蹈',
    id: '5'
  },
  {
    text: '科技',
    id: '6'
  },
  {
    text: '游戏',
    id: '7'
  },
  {
    text: '娱乐',
    id: '8'
  },
  {
    text: '鬼畜',
    id: '9'
  },
  {
    text: '电影',
    id: '10'
  },
  {
    text: '电视剧',
    id: '11'
  },
  {
    text: '纪录片',
    id: '12'
  },
  {
    text: '影视',
    id: '13'
  },
  {
    text: '时尚',
    id: '14'
  },
  {
    text: '生活',
    id: '15'
  },
  {
    text: '广告',
    id: '16'
  },
  {
    text: '直播',
    id: '17'
  },
  {
    text: '相簿',
    id: '18'
  },
  {
    text: '学习',
    id: '19'
  }
]
