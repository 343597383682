export default [
  {
    link: '#/video/20',
    imgSrc: 'https://i1.hdslb.com/bfs/archive/d398687b1cb04a42213d75f4ed8c6b5113fc7f36.jpg@480w_300h.webp'
  },
  {
    link: '#/video/21',
    imgSrc: "http://i2.hdslb.com/bfs/archive/ef9c31e4a01a018305481f6b1b1cc6560aa4473b.jpg@480w_300h.webp",
  },
  {
    link: '#/video/22',
    imgSrc: 'http://i1.hdslb.com/bfs/archive/49f4745a5abb34c8f0138d305704538c7f0c58a1.jpg@480w_300h.webp'
  },
  {
    link: '#/video/23',
    imgSrc: 'http://i2.hdslb.com/bfs/archive/58af8bb03869d73f25b3a1469284838933afef8b.jpg@480w_300h.webp'
  },
 
]
