export default [
  {
    id: '1',
    avatar: 'https://i1.hdslb.com/bfs/face/bb856d0bed8ef8abc0544fd5bd86da4cedf62a04.jpg@60w_60h_1c.png',
    username: '峰岛达裕',
    content: '太强了吧，质量好高啊~',
    date: '12-15'
  },
  {
    id: '2',
    avatar: 'https://i0.hdslb.com/bfs/face/7dad2615a4e8fef238d9b8000593a529fbcc0b90.jpg@60w_60h_1c.png',
    username: '黑狌白鸾',
    content: '鲁迅说过，如果你有一个苹果，我有一个苹果，我们交换，一人还是一个苹果，如果你有一个网站，我有一个网站，我们交换那我们双方就有两个网站',
    date: '12-15'
  },
  {
    id: '3',
    avatar: 'https://i2.hdslb.com/bfs/face/27ec942e8d4e6e024d3a9f11240d81a0aa90caca.jpg@60w_60h_1c.png',
    username: '马猴烧酒柊筱娅',
    content: '等等，那这样的话，牧草君岂不是一个视频打了1w多个广告',
    date: '12-15'
  },
  {
    id: '4',
    avatar: 'https://i1.hdslb.com/bfs/face/7f3605a11f54e5ac1719e7877c641a19da137d32.jpg@60w_60h_1c.png',
    username: 'NAN某',
    content: '我经常逛的那些网站也有很多广告(｀・ω・´)',
    date: '12-15'
  },
  {
    id: '5',
    avatar: 'https://i0.hdslb.com/bfs/face/54923922eea6532c2dff95e45743b6a1203c0230.jpg@60w_60h.webp',
    username: '牧草君',
    content: '你们赶快去这个王网站了里面找找好玩的，我已经找到好几个(✪▽✪)好康的网站了',
    date: '12-15'
  }
]
